
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';
import { Loader } from '@googlemaps/js-api-loader';
import MapApi from '@/module/api/MapApi';
import AddressLocation from '@/model/user/AddressLocation';
// import google from '@types/google.maps';

/* eslint-disable no-undef */
@Component({})
export default class MapView extends Vue {
  map: any;
  @Prop()addressLocation!:AddressLocation;
  check1 = true;
  check2 = false;
  @Watch('addressLocation')
  async onSetLocation():Promise<void> {
    if (this.addressLocation.lat === 0) return;
    if (!this.addressLocation.lat && !this.addressLocation.lng) {
      this.check1 = false;
      this.check2 = true;
      return;
    }
    const canvas = this.$refs?.mapCanvas as Element;
    await MapApi.showMap(canvas, this.addressLocation);
  }
}
