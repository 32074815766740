
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import UserApi from '@/module/api/UserApi';
import MonthReportApi from '@/module/api/MonthReportApi';
import UserBaseInfoClass from '@/model/user/UserBaseInfo';
import UserID from '@/model/user/UserID';
import UserUpdateItemInfo from '@/model/user/UserUpdateItemInfo';
import UserInfo from '@/model/user/UserInfo';
import MapView from '@/components/User/Map/MapView.vue';
import MonthReportInfoClass from '@/model/monthReport/MonthReportInfoClass';
import TypeJpDate from '@/model/type/TypeJpDate';

@Component({
  components: {
    InputJpDate,
    MapView
  }
})
export default class UserProfile extends Vue {
  @State('loginUser') loginUser!: UserBaseInfoClass;
  user:UserInfo = new UserInfo();
  userUpdateItemList:UserUpdateItemInfo[] = [];
  monthReportInfoList: MonthReportInfoClass[] = [];
  photoUrl: string = '';
  monthReportSDate:TypeJpDate = TypeJpDate.getTodayMonth();
  monthReportEDate:TypeJpDate = TypeJpDate.getTodayMonth();
  licenseSDate:TypeJpDate = new TypeJpDate('Showa', 60, 1, 1);
  licenseEDate:TypeJpDate = TypeJpDate.getTodayMonth();
  historySDate:TypeJpDate = TypeJpDate.getToday();
  historyEDate:TypeJpDate = TypeJpDate.getToday();
  historyLogo:boolean = false;
  historydateShowFlg:boolean = false;

  mounted(): void {
    if (this.$route.params.userId === undefined) {
      UserApi.findByID().then((res) => {
        this.user = res.data;
        this.getPhoto();
        MonthReportApi.findAll().then((monthResult) => {
          this.monthReportInfoList = monthResult.data;
        });
        UserApi.findUpdateItem().then((result) => {
          this.userUpdateItemList = result.data;
        });
      });
    } else {
      const userId:UserID = new UserID(this.$route.params.userId);
      UserApi.privfindByID(userId.value).then((res) => {
        this.user = res.data;
        this.getPhoto();
        MonthReportApi.findAllByPrivUser(userId.value).then((monthResult) => {
          this.monthReportInfoList = monthResult.data;
        });
        UserApi.privfindUpdateItem(userId.value).then((result) => {
          this.userUpdateItemList = result.data;
        });
      });
    }
  }
  async getPhoto():Promise<void> {
    if (this.user.engineerinfo.photo.value.length === 0) return;
    const res = await UserApi.getURL(this.user.engineerinfo.photo.value);
    this.photoUrl = res.data;
  }
  isUpdated(column:string): boolean {
    return this.userUpdateItemList.some((i) => i.updateFields.value === column);
  }
  downloadcsv(target:string, startDate:TypeJpDate, endDate:TypeJpDate):void {
    if (!startDate.isValid) return;
    if (!endDate.isValid) return;
    if (this.loginUser.priv.isAdminGroup) {
      window.open(`/api/v1/admin/${target}/download?userId=${this.user.userBaseInfo.userId.value}&startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`, '_blank');
      return;
    }
    window.open(`/api/v1/${target}/download?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`, '_blank');
  }
  historyUrl():void {
    if (!this.historySDate.isValid) return;
    if (!this.historyEDate.isValid) return;
    window.open(`/print.html#/resume/${this.user.userBaseInfo.userId.value}/${this.historyLogo}/${this.historydateShowFlg}/${this.historySDate.toDateString()}/${this.historyEDate.toDateString()}`, 'blank');
  }

  historyExcel():void{
    if (this.loginUser.priv.isAdminGroup) {
      window.open(`/api/v1/admin/history/createExcel/${this.user.userBaseInfo.userId.value}`, '_blank');
      return;
    }
    window.open('/api/v1/history/createExcel', '_blank');
  }
}
